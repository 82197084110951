import WebFormRepository from '@/shared/http/repositories/socialProject/web-form'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewWebForm from '@/views/tools/web-form/components/webForm/webForm.vue'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import WebFormModel from '@/shared/models/webForm'

export default {
  name: 'NovoWebForm',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewWebForm.name]: NewWebForm
  },

  data: () => ({
    placeholder: 'Digite um nome para o formulário',
    title: 'FORMULÁRIO WEB',
    webFormModel: new WebFormModel(),
    isNew: true
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Formulários WEB',
            href: this.$router.resolve({ name: 'web-form' }).href
          },
          {
            text: 'Novo Formulário WEB',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(webFormModel) {
      loading.push()
      WebFormRepository.Create(webFormModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
    
          loading.pop()
          toast.success('Formulário cadastrado com sucesso', 'NOVO FORMULÁRIO')
          Promise.resolve()
          return this.$router.replace({ name: 'web-form' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar formulário', 'ERRO')
        })
    }
  }
}
