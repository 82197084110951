import GuidUtils from '@/shared/utils/guid'

export default class WebForm {
  constructor({
    id,
    companyId,
    name,
    mnemonic,
    isActive,
    destination
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.companyId = companyId,
    this.name = name,
    this.mnemonic = mnemonic
    this.isActive = isActive
    this.destination = destination
  }
}