const WebFormDestionation = {
  TO_STUDENT: { 
    id: 0, 
    name:'Estudantes' 
  },
  TO_TEACHERS: {
    id: 1,
    name: 'Professores'
  },
  TO_RESPONSIBLE: {
    id: 2,
    name: 'Responsável'
  },
  TO_DETACHED: {
    id: 3,
    name: 'Avulso'
  }
}

Object.freeze(WebFormDestionation)

export default WebFormDestionation
